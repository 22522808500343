import React, { useState, useEffect } from "react";
import { Button, Input, Progress, Radio, Space, message } from "antd";
import { BASE_URL } from "../../api";
import Loader from "../../components/loader";

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import SkillLogo from "../../assets/images/skill-logo.png";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Alignment } from "react-data-table-component";
import html2canvas from "html2canvas";
import skillgapimg from "../../assets/images/reports/skillgapreport.png";
import barcode from "../../assets/images/barcode-2.png";
import { questionData } from "../../constant";
import { useNavigate } from "react-router-dom";

const footerText =
  "Copyright © 2024 - 2024 Skillmotion.ai. All Rights Reserved. To report contact info@skillmotion.ai";

const AssessmentSkillTest = () => {
  let assessment_id = localStorage.getItem("assessment_id");

  const navigate = useNavigate();

  let assessment_name = localStorage.getItem("assessment_name");
  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const [activeSkills, setActiveSkills] = useState(0);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [jobRoles, setJobRoles] = useState({});
  const [assessmentSkills, setAssessmentSkills] = useState({});
  const [score, setScore] = useState({});
  const [proficiency_level, setProficiency_level] = useState({});
  const [answersData, setAnswersData] = useState({});

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [remainingTime, setRemainingTime] = useState(20 * 60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [nextDisable, setNextDisable] = useState(true);
  const [askedQues, setAskedQues] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [letestQues, setLetestQues] = useState(-1);
  const [levelCount, setLevelCount] = useState(0);

  const getQuesLength = (ques) => {
    return ques;
  };

  const now = new Date();

  function cleanString(str) {
    if (str) {
      return str?.replace(/^[a-zA-Z]\)|^[a-zA-Z]\./, "")?.trim();
    }
    return "";
  }

  const handleNextQuestion = (index) => {
    // Log current state for debugging
    setLetestQues(index);
    console.log(
      assessmentQuestions[letestQues],
      answers[letestQues],
      index,
      "askProfi data"
    );

    // Get the current question and the selected answer for the current question
    const question = assessmentQuestions[letestQues];
    const selectedQuestion = answers[letestQues];

    // Update asked questions
    const updatedAskedQuestions = { ...askedQues };
    const newIndex = Object.keys(updatedAskedQuestions).length + 1;
    updatedAskedQuestions[newIndex] = question?.question;
    setAskedQues(updatedAskedQuestions);

    // Calculate next index
    const nextIndex = index + 1;
    if (nextIndex < assessmentQuestions.length) {
      const nextQuestion = assessmentQuestions[nextIndex]?.question;
      const indexOfNextQuestion = assessmentQuestions.findIndex(
        (it) => it?.question === nextQuestion
      );

      console.log(
        indexOfNextQuestion,
        currentQuestion,
        getQuesLength(assessmentQuestions.length),
        Object.keys(answers).length,
        "anslog ,indexOfNextQuestion currentQuestion totalquestions, totalanswers"
      );
      console.log(answers, "answers");

      // Move to the next question if there are more questions to show
      if (currentQuestion < assessmentQuestions.length - 1) {
        setNextDisable(true);
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        setCurrentIndex(indexOfNextQuestion);
      }
    } else {
      // Handle case when there are no more questions (e.g., show a completion message)
      console.log("No more questions.");
    }
  };

  const handleRadioChange = (e, index, data) => {
    const selectedValue = e.target.value;

    setAnswers((prevState) => ({
      ...prevState,
      [index]: { questionId: data.question_id, selectedOption: selectedValue },
    }));

    setNextDisable(false);
  };

  function removeNumber(str) {
    if (str) {
      const regex = /^\d+\.\s*/;
      const result = str?.replace(regex, "");

      return result;
    }
    return "";
  }

  useEffect(() => {
    setAssessmentQuestions([]);
    fetchAssessmentQuestions(assessment_id);
  }, []);

  const fetchAssessmentQuestions = async (assessmentId) => {
    setLoading(true);
    setLoadingFor("Getting Assessment Questions");
    try {
      const response = await fetch(
        `${BASE_URL}/api/assessment-questions/${assessmentId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        const questions = data.data.questions;
        console.log(questions, "questions");
        setAssessmentQuestions(questions);
        setJobRoles(data.data.roles);
        setAssessmentSkills(data.data.skills);
      } else {
        console.error("Failed to retrieve questions:", data.message);
      }
    } catch (error) {
      console.error("Error fetching assessment questions:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const handleSubmit = async () => {
    console.log("total answers", Object.keys(answers).length);
    console.log("total questions", assessmentQuestions.length);

    if (Object.keys(answers).length !== assessmentQuestions.length) {
      alert("Please answer all questions before submitting.");
      return;
    }

    const formattedAnswers = assessmentQuestions.map((q, idx) => ({
      questionId: q.question_id,
      selectedOption: answers[idx]?.selectedOption || null,
    }));

    setLoading(true);
    setLoadingFor("Submitting Answers");

    try {
      const response = await fetch(
        `${BASE_URL}/api/assessment-learner-answers/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`,
          },
          body: JSON.stringify({
            assessment_id,
            answers: formattedAnswers,
          }),
        }
      );

      const data = await response.json();

      // Log the response data for debugging
      console.log(data);

      if (!response.ok) {
        if (data.error) {
          message.error(data.error);
        } else {
          message.error("An error occurred while submitting your answers.");
        }
      } else {
        if (data.success) {
          message.success(data.message || "Answers submitted successfully!");
          setScore(data.score.toString());
          setProficiency_level(data.proficiency_level);
          setAnswersData(data.answer_data);

          console.log("assessmentQuestions", assessmentQuestions);
          console.log("answersData", data.answer_data);

          generatePdf(
            data.score.toString(),
            data.proficiency_level,
            data.answer_data
          );
          navigate("/dashboard/renewal");
        } else {
          message.error(data.error || "Failed to submit answers.");
          console.error("Failed to submit answers:", data.message);
        }
      }
    } catch (error) {
      console.error("Error submitting answers:", error.message);
      message.error("Error submitting answers: " + error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  function calculatePercentages(assessmentQuestions, answersData) {
    const results = {};

    // Initialize results object by skill type and skill name
    assessmentQuestions.forEach(
      ({ skill_name, skill_type, skill_description }) => {
        if (!results[skill_type]) {
          results[skill_type] = {};
        }
        if (!results[skill_type][skill_name]) {
          results[skill_type][skill_name] = {
            description: skill_description,
            correct: 0,
            total: 0,
          };
        }
      }
    );

    // Calculate correct answers and totals
    assessmentQuestions.forEach(({ skill_name, skill_type, question_id }) => {
      const answer = answersData.find((ans) => ans.question_id === question_id);
      if (answer) {
        results[skill_type][skill_name].total += 1;
        if (answer.is_correct) {
          results[skill_type][skill_name].correct += 1;
        }
      }
    });

    // Convert to percentages and determine proficiency levels
    const percentages = Object.keys(results).map((skill_type) => ({
      skill_type,
      skills: Object.keys(results[skill_type]).map((skill_name) => {
        const { correct, total, description } = results[skill_type][skill_name];
        const percentage = Math.round((correct / total) * 100);
        let prof_level = "Low"; // Default proficiency level

        if (percentage >= 85) {
          prof_level = "High";
        } else if (percentage >= 50) {
          prof_level = "Medium";
        }

        return {
          skill_name,
          percentage,
          prof_level,
          skill_description: description, // Include skill description
        };
      }),
    }));

    return percentages;
  }

  const generatePdf = async (myscore, mylevel, myanswersdata) => {
    let pdftext = "";
    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(
      StandardFonts.TimesRomanBold
    );

    const pageWidth = 600;
    const pageHeight = 800;
    const margin = 50;
    const lineHeight = 20;
    const maxWidth = pageWidth - 2 * margin;
    const user = JSON?.parse(localStorage.getItem("user"));

    const drawFooter = (page) => {
      const { width } = page.getSize();
      const textWidth = timesRomanFont.widthOfTextAtSize(footerText, 10);
      const xPosition = (width - textWidth) / 2;

      page.drawText(footerText, {
        x: xPosition,
        y: 20,
        size: 10,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      });
    };

    const drawBulletPoint = (page, x, y) => {
      const bulletRadius = 2;
      const bulletAdjustment = 12;
      page.drawCircle({
        x: x,
        y: y - bulletAdjustment,
        size: bulletRadius,
        color: rgb(0, 0, 0),
      });
    };

    const addSectionTitleFirstText = (page, text, yPosition, fontSize) => {
      const textWidth = timesRomanBoldFont.widthOfTextAtSize(text, fontSize);
      const xPosition = (pageWidth - textWidth) / 2;

      page.drawText(text, {
        x: xPosition,
        y: yPosition - 140,
        size: fontSize,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
      });
    };

    const addSectionText = (
      page,
      text,
      xPosition,
      yPosition,
      fontSize,
      font,
      color
    ) => {
      const availableWidth = page.getWidth() - xPosition - margin;
      page.drawText(text, {
        x: xPosition,
        y: yPosition,
        size: fontSize,
        font: font,
        color: color,
        maxWidth: availableWidth,
        lineHeight: fontSize * 1.2,
        align: "left",
      });
    };

    const addSectionTitleStart = (page, text, yPosition) => {
      page.drawText(text, {
        x: 50,
        y: yPosition - 10,
        size: 20,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
      });
    };

    const addSectionSkillTitle = (page, text, yPosition) => {
      page.drawText(text, {
        x: 50,
        y: yPosition - 10,
        size: 20,
        weight: "bold",
        font: timesRomanFont,
        color: rgb(0, 0, 0),
      });
    };

    const addUserName = (page, text, x, y, size = 18, options = {}) => {
      const textWidth = timesRomanBoldFont.widthOfTextAtSize(text, 160);
      page.drawText(text, {
        x: x,
        y: y - 215,
        size: size,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
        ...options,
      });
      page.drawLine({
        start: { x: 50, y: y - 300 },
        end: { x: 50 + textWidth, y: y - 300 },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    };
    const wrapTextLink = (text, font, size, maxWidth) => {
      const lines = [];
      let line = "";
      for (let i = 0; i < text.length; i++) {
        const testLine = line + text[i];
        const testWidth = font.widthOfTextAtSize(testLine, size);

        if (testWidth > maxWidth && line !== "") {
          lines.push(line);
          line = text[i];
        } else {
          line = testLine;
        }
      }
      if (line) {
        lines.push(line);
      }
      return lines;
    };
    const wrapText = (text, font, size, maxWidth) => {
      if (typeof text !== "string" || text.trim() === "") {
        return [];
      }

      const words = text.split(" ");
      let lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = font.widthOfTextAtSize(currentLine + " " + word, size);
        if (width < maxWidth) {
          currentLine += " " + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    };

    const addJobTitle = (page, text, x, y, size = 18, options = {}) => {
      const lines = wrapText(text, timesRomanBoldFont, size, maxWidth - x);
      lines.forEach((line) => {
        page.drawText(line, {
          x: x,
          y: y - 220,
          size: size,
          font: timesRomanBoldFont,
          color: rgb(0, 0, 0),
          ...options,
        });
        y -= lineHeight;
      });
    };

    const addSectionTitle = (
      page,
      text,
      yPosition,
      fontSize,
      font = timesRomanBoldFont
    ) => {
      const availableWidth = page.getWidth() - 2 * margin; // Adjust for margins

      // Split text into lines based on available width
      page.drawText(text, {
        x: margin,
        y: yPosition,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
        maxWidth: availableWidth,
        lineHeight: fontSize * 1.2, // Adjust line height as needed
        align: "left", // or 'center' or 'right'
      });
    };

    const getTextHeight = (text, fontSize, lineHeight, maxWidth, font) => {
      // Create a canvas context to measure text
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      context.font = `${fontSize}px ${font}`;

      const words = text.split(" ");
      let lines = [];
      let currentLine = "";

      words.forEach((word) => {
        const testLine = currentLine + word + " ";
        const testWidth = context.measureText(testLine).width;

        if (testWidth > maxWidth) {
          lines.push(currentLine.trim());
          currentLine = word + " ";
        } else {
          currentLine = testLine;
        }
      });

      lines.push(currentLine.trim());

      const numberOfLines = lines.length;
      return numberOfLines * fontSize * lineHeight;
    };

    const addSkillTextBlock = (page, text, x, y, size = 15, options = {}) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
        ...options,
      });
    };

    const addCoreSkillTextBlock = (
      page,
      text,
      x,
      y,
      size = 13,
      options = {}
    ) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
        ...options,
      });
    };

    const addTextBlock = (page, text, x, y, size = 12, options = {}) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
        ...options,
      });
    };

    const addBoldTextBlock = (page, text, x, y, size = 12, options = {}) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
        ...options,
      });
    };

    const addLinksBlock = (page, text, x, y, size = 12, options = {}) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanFont,
        color: rgb(0, 0, 1),
        ...options,
      });
    };

    const addCompasTextBlock = (page, text, x, y, size = 13, options = {}) => {
      page.drawText(text, {
        x: x,
        y: y - 20,
        size: size,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
        ...options,
      });
    };

    const drawVerticalLine = (page, yPosition, height) => {
      const xPosition = page.getWidth() / 2;

      page.drawLine({
        start: { x: xPosition, y: yPosition },
        end: { x: xPosition, y: yPosition - height },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    };

    const addSkillsWithPagination = (skills, page, yPosition) => {
      skills.forEach((skill) => {
        const lines = wrapText(
          `${skill.skillName}: ${skill.skillProficiencies}`,
          timesRomanFont,
          12,
          maxWidth
        );
        lines.forEach((line) => {
          if (yPosition < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            yPosition = pageHeight - margin;
          }
          drawBulletPoint(page, margin - 10, yPosition - 5);
          addCompasTextBlock(page, line, margin, yPosition);
          yPosition -= lineHeight;
        });
      });
      return yPosition;
    };

    const marioUrl = SkillLogo;
    const marioImageBytes = await fetch(marioUrl).then((res) =>
      res.arrayBuffer()
    );
    const marioImage = await pdfDoc.embedPng(marioImageBytes);

    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    let yPosition = pageHeight - margin;

    page.drawImage(marioImage, {
      x: 420,
      y: yPosition - 100,
      width: 150,
      height: 150,
    });

    addSectionTitleFirstText(page, "SkillSphere Report", yPosition, 20);
    yPosition -= 20;

    const skillgapimgBytes = await fetch(skillgapimg).then((res) =>
      res.arrayBuffer()
    );
    const skillimage = await pdfDoc.embedPng(skillgapimgBytes);

    page.drawImage(skillimage, {
      x: margin,
      y: yPosition - 500,
      width: 200,
      height: 238,
    });

    let verticalx = 348;
    drawVerticalLine(page, yPosition - 200, verticalx);

    let xPosition = margin + 280;
    const orangeColor = rgb(1, 0.65, 0);
    const defaultColor = rgb(0, 0, 0);

    let specialyPosition = yPosition - 340;
    addSectionText(
      page,
      "Assessment Report",
      xPosition,
      specialyPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    specialyPosition -= 30;
    pdftext = "Candidate Name: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 10);
    pdftext = user?.name;
    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanFont,
      defaultColor
    );

    specialyPosition -= 12;
    xPosition = margin + 280;
    pdftext = "Date of Report: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 10);
    pdftext = `${now.getDate().toString().padStart(2, "0")}-${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${now.getFullYear()}`;

    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanFont,
      defaultColor
    );

    specialyPosition -= 12;
    xPosition = margin + 280;
    pdftext = "Assessment: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 10);
    pdftext = assessment_name;

    addSectionText(
      page,
      pdftext,
      xPosition,
      specialyPosition,
      10,
      timesRomanFont,
      defaultColor
    );

    let textwidth = timesRomanBoldFont.widthOfTextAtSize(
      "Assesment report",
      12
    );
    xPosition = (verticalx - margin) / 2 - textwidth / 2;
    yPosition -= 520;
    pdftext = "Assesment report";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      rgb(0, 0, 0)
    );

    textwidth = timesRomanBoldFont.widthOfTextAtSize(
      "powered by Skillsphere@Skillmotion.AI",
      8
    );
    xPosition = (verticalx - margin) / 2 - textwidth / 2;

    yPosition -= 12;
    addSectionText(
      page,
      "powered by Skillsphere@Skillmotion.AI",
      xPosition,
      yPosition,
      8,
      timesRomanBoldFont,
      rgb(0, 0, 0)
    );

    page = pdfDoc.addPage([pageWidth, pageHeight]);

    xPosition = margin;
    yPosition = pageHeight - margin;
    addSectionText(
      page,
      "Introduction to Skillmotion.AI",
      xPosition,
      yPosition,
      16,
      timesRomanBoldFont,
      defaultColor
    );

    yPosition -= 30;
    pdftext = "Skillmotion.AI";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );
    xPosition = xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      " is a cutting-edge talent insights and analytics platform designed to revolutionize how";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );
    xPosition = margin;
    yPosition -= 20;
    pdftext =
      "organizations and individuals identify and bridge skill gaps. By leveraging advanced artificial";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 20;
    pdftext = "intelligence and machine learning algorithms – the ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );
    xPosition = xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = " Skillsphere ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Skillmotion.AI provides precise and";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 20;
    pdftext =
      "actionable insights into the skills needed to excel in any given role.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 30;
    pdftext =
      "Our platform offers personalized recommendations for training, development, and mentorship, ensuring that users can effectively navigate their development and achieve their professional goals.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    page.drawLine({
      start: { x: margin, y: yPosition - 30 },
      end: { x: pageWidth - margin, y: yPosition - 30 },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    xPosition = margin;
    yPosition -= 60;
    addSectionText(
      page,
      "Methodology",
      xPosition,
      yPosition,
      16,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 30;
    addSectionText(
      page,
      "The Skillmotion.AI Skill Gap Report utilizes a proprietary methodology combining various data sources, including industry benchmarks, job role requirements, and individual skill assessments. Our AI-driven approach involves the following steps:",
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 60;
    pdftext = "1.    ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    let list_start = xPosition;
    pdftext = "Role Analysis: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Detailed examination of the target role's requirements.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "2.    ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Skill Assessment: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "Evaluation of the individual's current skill set through assessments and";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "self-reports.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "3.   ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Gap Identification: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "Identification of skill gaps by comparing the individual's skills against the";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "target role's requirements.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "4.   ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Recommendation Generation: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Providing personalized recommendations for training courses";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "and potential mentors.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition -= 30;
    page.drawLine({
      start: { x: margin, y: yPosition },
      end: { x: pageWidth - margin, y: yPosition },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    yPosition -= 30;
    xPosition = margin;
    addSectionText(
      page,
      "Executive Summary:",
      xPosition,
      yPosition,
      16,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 30;
    addSectionText(
      page,
      "This Assessment aims to test the proficiency of the candidate on the following skills. A brief description of the skills helps you to understand the skill a bit better.",
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition -= 30;
    pdftext =
      "The assessment engine uses an adaptive process to better calibrate the proficiency level.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    let yskill = 30;
    assessmentSkills.map((skill, index) => {
      // Check if yPosition is too low, indicating the need for a new page
      xPosition = margin;
      if (yPosition - yskill <= margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        xPosition = margin;
        yPosition = pageHeight - margin;
      } else {
        yPosition -= yskill;
      }

      pdftext = skill.name + ": ";
      addSectionText(
        page,
        pdftext,
        xPosition,
        yPosition,
        12,
        timesRomanBoldFont,
        defaultColor
      );

      // Adjust yPosition for description
      xPosition = margin;
      yPosition -= 20;
      addSectionText(
        page,
        skill.description,
        xPosition,
        yPosition,
        12,
        timesRomanFont,
        defaultColor
      );

      // Calculate text height dynamically
      yskill =
        getTextHeight(
          skill.name + ": " + skill.description,
          12,
          3,
          pageHeight,
          timesRomanFont
        ) + 10;
    });

    page = pdfDoc.addPage([pageWidth, pageHeight]);
    yPosition = pageHeight - margin;
    xPosition = margin;

    pdftext = "How to Read the Skill Gap Report";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    yPosition -= 30;
    pdftext = "This report is divided into several key sections:";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 30;
    pdftext = "1.  ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);

    list_start = xPosition;

    pdftext = "Overall Skill Proficiency: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "Based on the competency set we aggregate a overall skill level for";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "technical and core skills.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "2.   ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Technical Skills: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "this section showcases your respective proficiency level for the various";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "technical skills assessed.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "3.   ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Core Skills: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "this section showcases your respective proficiency level for the various core skills";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "assessed.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext = "4.   ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12);
    pdftext = "Recommendations: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "Suggested courses and potential mentors to help bridge the identified skill";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = list_start;
    yPosition -= 15;
    pdftext = "gaps.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition -= 30;
    page.drawLine({
      start: { x: margin, y: yPosition },
      end: { x: pageWidth - margin, y: yPosition },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    yPosition -= 30;
    xPosition = margin;
    addSectionText(
      page,
      "Overall Skill Proficiency",
      xPosition,
      yPosition,
      16,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 30;
    pdftext = "Proficiency Level: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition += timesRomanFont.widthOfTextAtSize(pdftext, 12) + 20;

    pdftext = myscore + " " + mylevel;
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;
    pdftext = "Below 50 - Low Proficiency: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext =
      "Limited understanding and application of the skill, with frequent difficulties in executing tasks and solving problems related to it.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 40;

    pdftext = "50-85 Medium Proficiency: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext =
      "Adequate knowledge and application of the skill, with occasional challenges and room for improvement in handling complex scenarios.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 40;

    pdftext = "Above 85 High Proficiency: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin + 20;
    yPosition -= 20;

    pdftext =
      "Strong grasp and effective application of the skill, demonstrating consistent capability in executing tasks and solving problems with minimal guidance.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );
    xPosition = margin + 20;
    yPosition -= 40;

    const percentages = calculatePercentages(
      assessmentQuestions,
      myanswersdata
    );

    console.log("questions", assessmentQuestions);
    console.log("answersData", myanswersdata);

    percentages.forEach(({ skill_type, skills }) => {
      // Adjust yPosition for the skill_type title
      yPosition -= 30;
      ({ page, yPosition } = checkPageOverflow(
        pdfDoc,
        page,
        yPosition,
        pageWidth,
        pageHeight,
        margin
      ));

      page.drawLine({
        start: { x: margin, y: yPosition },
        end: { x: pageWidth - margin, y: yPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      let skill_title = "Technical Skills Assessment";
      if (skill_type === "tsc") {
        skill_title = "Technical Skills Assessment";
      } else {
        skill_title = "Core Skills Assessment";
      }
      xPosition = margin;
      yPosition -= 30;
      addSectionText(
        page,
        skill_title,
        xPosition,
        yPosition,
        16,
        timesRomanBoldFont,
        defaultColor
      );

      // Loop through each skill in the current skill_type group
      xPosition = margin;
      yPosition -= 30;
      addSectionText(
        page,
        "Skill Area",
        xPosition,
        yPosition,
        10,
        timesRomanBoldFont,
        defaultColor
      );

      xPosition = margin + pageWidth / 3;
      addSectionText(
        page,
        "Assessment Score",
        xPosition,
        yPosition,
        10,
        timesRomanBoldFont,
        defaultColor
      );

      xPosition = margin + 2 * (pageWidth / 3);
      addSectionText(
        page,
        "Level (Low/Medium/High)",
        xPosition,
        yPosition,
        10,
        timesRomanBoldFont,
        defaultColor
      );

      let skill_index = 0;
      let overallpercentage = 0;
      skills.forEach(({ skill_name, percentage, prof_level }) => {
        // Adjust yPosition for each skill
        yPosition -= 30;
        ({ page, yPosition } = checkPageOverflow(
          pdfDoc,
          page,
          yPosition,
          pageWidth,
          pageHeight,
          margin
        ));
        xPosition = margin;
        addSectionText(
          page,
          `${skill_name}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );

        xPosition = margin + pageWidth / 3;
        addSectionText(
          page,
          `${percentage}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );

        overallpercentage += percentage;
        xPosition = margin + 2 * (pageWidth / 3);
        addSectionText(
          page,
          `${prof_level}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );
        skill_index += 1;
      });

      overallpercentage = Math.round(overallpercentage / skills.length);
      let prof_level = "Low"; // Default proficiency level

      if (overallpercentage >= 85) {
        prof_level = "High";
      } else if (overallpercentage >= 50) {
        prof_level = "Medium";
      }
      if (skill_type === "tsc") {
        yPosition -= 30;
        xPosition = margin;
        addSectionText(
          page,
          "Overall Technical Skills",
          xPosition,
          yPosition,
          10,
          timesRomanBoldFont,
          defaultColor
        );

        xPosition = margin + pageWidth / 3;
        addSectionText(
          page,
          `${overallpercentage}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );

        xPosition = margin + 2 * (pageWidth / 3);
        addSectionText(
          page,
          `${prof_level}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );
      } else {
        yPosition -= 30;
        xPosition = margin;
        addSectionText(
          page,
          "Overall Core Skills",
          xPosition,
          yPosition,
          10,
          timesRomanBoldFont,
          defaultColor
        );

        xPosition = margin + pageWidth / 3;
        addSectionText(
          page,
          `${overallpercentage}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );

        xPosition = margin + 2 * (pageWidth / 3);
        addSectionText(
          page,
          `${prof_level}`,
          xPosition,
          yPosition,
          10,
          timesRomanFont,
          defaultColor
        );
      }
    });

    page = pdfDoc.addPage([pageWidth, pageHeight]);
    yPosition = pageHeight - margin;
    xPosition = margin;
    addSectionText(
      page,
      "Your Skills Gap Analysis",
      xPosition,
      yPosition,
      16,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = margin;
    yPosition -= 30;
    addSectionText(
      page,
      "This section provides a detailed analysis of the Current Skills vs Expected Skills and highlights the Areas for Improvement.",
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition -= 30;
    page.drawLine({
      start: { x: margin, y: yPosition },
      end: { x: pageWidth - margin, y: yPosition },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    // yPosition -= 30;
    // pdftext = "Our Recommendations:";
    // addSectionText(
    //   page,
    //   pdftext,
    //   xPosition,
    //   yPosition,
    //   18,
    //   timesRomanBoldFont,
    //   defaultColor
    // );

    // yPosition -= 30;
    // page.drawLine({
    //   start: { x: margin, y: yPosition },
    //   end: { x: pageWidth - margin, y: yPosition },
    //   thickness: 1,
    //   color: rgb(0, 0, 0),
    // });

    // yPosition -= 30;
    // pdftext = "Technical Skills:";
    // addSectionText(
    //   page,
    //   pdftext,
    //   xPosition,
    //   yPosition,
    //   12,
    //   timesRomanBoldFont,
    //   defaultColor
    // );

    // yPosition -= 30;
    // pdftext = "Soft Skills:";
    // addSectionText(
    //   page,
    //   pdftext,
    //   xPosition,
    //   yPosition,
    //   12,
    //   timesRomanBoldFont,
    //   defaultColor
    // );

    // page = pdfDoc.addPage([pageWidth, pageHeight]);
    // yPosition = pageHeight - margin;
    // xPosition = margin;

    yPosition -= 30;
    pdftext = "Conclusion:";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );
    xPosition = margin;
    yPosition -= 20;
    pdftext =
      "The recommendations outlined in this report, can help you address your skill gaps and enhance your readiness for the target role. Skillmotion.AI is committed to supporting your professional development journey, ensuring you have the tools and resources needed to succeed.";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition =
      yPosition -
      getTextHeight(
        pdftext,
        12,
        3,
        pageHeight - 2 * (margin + 20),
        timesRomanFont
      ) -
      30;
    xPosition = margin;
    pdftext = "Contact Us: ";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanBoldFont,
      defaultColor
    );

    xPosition = xPosition + timesRomanBoldFont.widthOfTextAtSize(pdftext, 12);
    pdftext =
      "For further assistance or questions, please contact Info@skillmotion.ai";
    addSectionText(
      page,
      pdftext,
      xPosition,
      yPosition,
      12,
      timesRomanFont,
      defaultColor
    );

    yPosition -= 20;

    const sbarcodeBytes = await fetch(barcode).then((res) => res.arrayBuffer());
    const sbarcodeImage = await pdfDoc.embedPng(sbarcodeBytes);

    let barCodeHeight = sbarcodeImage.height;
    let barcodeWidth = sbarcodeImage.width;

    xPosition = (pageWidth - barcodeWidth) / 2;
    yPosition -= barCodeHeight + 30;
    page.drawImage(sbarcodeImage, {
      x: xPosition,
      y: yPosition,
      width: barcodeWidth,
      height: barCodeHeight,
    });

    drawFooter(page);

    const pdfBytes = await pdfDoc.save();
    // report_Datetime_learnername
    // Create a new Date object

    // Function to format date as DD-MON-YYYY
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()]; // Get three-letter month name
      const year = date.getFullYear();

      return `${day}_${month}_${year}`; // Use underscores as separators
    };

    // Function to format time as HH-mm-ss
    const formatTime = (date) => {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}-${minutes}-${seconds}`; // Use hyphens as separators
    };

    // Format the date and time
    const formattedDate = formatDate(now);
    const formattedTime = formatTime(now);

    // Replace spaces with underscores in the user's name
    const report_userName = user?.name?.replace(/\s+/g, "_") || "Unknown_User";

    // Construct the report name
    const reportname = `assesment_report_${report_userName}_${formattedDate}_${formattedTime}.pdf`;

    saveAssessmentFile(pdfBytes, reportname);
    
    saveAs(new Blob([pdfBytes], { type: "application/pdf" }), reportname);
    // now we want to save this file to UserAssessment of id assessment_id
  };

  const saveAssessmentFile = async (pdfBytes, reportname) => {
    const formData = new FormData();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    formData.append('file', pdfBlob, reportname); // file field
    formData.append('assessment_id', assessment_id); // id field
    
    try {
      const response = await fetch(`${BASE_URL}/api/save-assessment-pdf/`, {
        method: "POST",
        headers: {
          // Authorization is passed in headers; `FormData` automatically sets the correct multipart/form-data header
          Authorization: `Token ${user?.token}`,
        },
        body: formData, // pass FormData object in body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('File saved successfully:', result);
      return result;

    } catch (error) {
      console.error('Error saving file:', error);      
    }
  }

  function checkPageOverflow(
    pdfDoc,
    page,
    yPosition,
    pageWidth,
    pageHeight,
    margin
  ) {
    if (yPosition - margin <= 0) {
      page = pdfDoc.addPage([pageWidth, pageHeight]);
      yPosition = pageHeight - margin;
    }
    return { page, yPosition };
  }

  return (
    <>
      {loading && <Loader text={loadingFor} />}
      <div className="assessment-container">
        <h2>Assessment Questions</h2>
        <h3>{assessment_name}</h3>
        <div className="devops-box-wrap">
          <div className="dev-ops-list">
            {assessmentQuestions?.length > 0 ? (
              <>
                <div className="dev-title d-flex align-items-center justify-content-between">
                  <h2>{assessmentQuestions[currentIndex]?.skill_name}</h2>
                </div>
                <div className="dev-test-question">
                  <h3>
                    {currentQuestion + 1}. {""}
                    {removeNumber(assessmentQuestions[currentIndex]?.question)}
                  </h3>
                  <div className="question-list">
                    <Radio.Group
                      value={answers[currentIndex]?.selectedOption}
                      onChange={(e) =>
                        handleRadioChange(
                          e,
                          currentIndex,
                          assessmentQuestions[currentIndex]
                        )
                      }
                    >
                      <Space direction="vertical">
                        <Radio value="A">
                          {cleanString(
                            assessmentQuestions[currentIndex]?.option1
                          ) || "none"}
                        </Radio>
                        <Radio value="B">
                          {cleanString(
                            assessmentQuestions[currentIndex]?.option2
                          ) || "none"}
                        </Radio>
                        <Radio value="C">
                          {cleanString(
                            assessmentQuestions[currentIndex]?.option3
                          ) || "none"}
                        </Radio>
                        <Radio value="D">
                          {cleanString(
                            assessmentQuestions[currentIndex]?.option4
                          ) || "none"}
                        </Radio>
                        {/* <Radio >
				{assessmentQuestions[currentIndex]?.answer}
			  </Radio> */}
                      </Space>
                    </Radio.Group>
                  </div>
                </div>
              </>
            ) : (
              <div className="dev-test-question">Please wait...</div>
            )}

            <div className="dev-ops-footer">
              <div className="progressbar">
                <span>{`Question ${currentQuestion + 1} / ${getQuesLength(
                  assessmentQuestions?.length
                )}`}</span>
                <Progress
                  percent={
                    ((currentQuestion + 1) /
                      getQuesLength(assessmentQuestions?.length)) *
                    100
                  }
                  showInfo={false}
                />
              </div>
              {getQuesLength(assessmentQuestions?.length) - 1 ==
              currentQuestion ? (
                <Button
                  className="primary-btn"
                  disabled={loading || nextDisable}
                  onClick={() => handleSubmit()}
                >
                  {loading ? "Processing..." : "Finish Test"}
                </Button>
              ) : (
                <Button
                  className="primary-btn"
                  disabled={nextDisable}
                  onClick={() => handleNextQuestion(currentQuestion)}
                >
                  Next Question
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentSkillTest;
